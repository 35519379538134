import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {useForm, useFieldArray} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import CreateShopEntryTable from "./createShopEntryTable";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormNoteAlert from "../../../forms/form-control/FormNoteAlert";

/**
 * CreateShopEntry Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateShopEntry = () => {
    const [payPeriod, setPayPeriod] = useState({});
    const [users, setUsers] = useState([]);
    const [ecOptions, setEcOptions] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [totalHours, setTotalHours] = useState(0.00);
    const [costCodes, setCostCodes] = useState([]);
    const [periodLockedStatus, setPeriodLockedStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {register, control, handleSubmit, errors, reset, watch,getValues, setValue} = useForm();
    const {fields, append, remove} = useFieldArray({
        control,
        name: "time_entries"
    });
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    // save form handler
    const onSubmit = (data) => {
        setIsLoading(true);
        apiClient
            .post('createShopTimeEntries', data)
            .then(response => {
                reset();
                toast.success(response.data.message);
                setShowButton(false);
            })
            .catch(function () {
                setIsLoading(false);
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    // get list of options
    useEffect(() => {
        // get period status
        apiClient
            .get('isLocked')
            .then(response => {
                setPeriodLockedStatus(response.data);
            });

        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data);
            });

        apiClient
            .get('getShoEmployees')
            .then(response => {
                setUsers(
                    response.data.map(
                        ({id, first_name, last_name}) =>
                            ({value: id, label: first_name + ' ' + last_name})
                    )
                );
            });

        //get EC Options
        apiClient
            .get('getEcOptionsForShop')
            .then(response => {
                setEcOptions(response.data);
            });

        apiClient
            .get('getAllAssets')
            .then(response => {
                setEquipments(
                    response.data.map(
                        ({id, equipment_number, make, model, description}) =>
                            ({value: id, label: equipment_number + ' ' +  make + ' ' + model + ' ' + description, equipment_number})
                    )
                );
            });

        //get active jobs
        apiClient
            .get('getAllActiveJobs')
            .then(response => {
                setJobs(
                    response.data.jobs.map(
                        ({id, job_number}) => ({id: id, name: job_number})
                    )
                );
            });

        //get cost codes for ML revenue group
        apiClient
            .post('getCostCodeByRevenueGroupId', {'revenue_group_id': 12})
            .then(response => {
                setCostCodes(
                    response.data.map(
                        ({id, description, code}) => ({value: id, label: description, code})
                    )
                );
            });
    }, []);

    useEffect(() => {
        if (payPeriod.start_date) {
            setStart(payPeriod.start_date);
        }

        if (payPeriod.end_date) {
            setEnd(payPeriod.end_date);
        }
    }, [payPeriod]);
    return (
        <>
            <Breadcrumb title="Create Shop Time Entry" parent="Time Entry"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormNoteAlert
                    className={(periodLockedStatus) ? "alert-danger" : "alert-success"}
                    label={
                        (periodLockedStatus)
                            ?
                                'Pay Period is Locked'
                            :
                                'Current Pay Period: ' + start + ' - ' + end
                    }
                />
                <CreateShopEntryTable
                    register={register}
                    control={control}
                    errors={errors}
                    fields={fields}
                    append={append}
                    remove={remove}
                    setValue={setValue}
                    ecOptions={ecOptions}
                    users={users}
                    equipments={equipments}
                    jobs={jobs}
                    costCodes={costCodes}
                    payPeriod={payPeriod}
                    watch={watch}
                    getValues={getValues}
                    setShowButton={setShowButton}
                    showButton={showButton}
                    setTotalHours={setTotalHours}
                    totalHours={totalHours}
                />
                {showButton &&
                    <FormSubmitButton
                        id="create_button"
                        className="col-md-1"
                        label="Create"
                    />
                }
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default CreateShopEntry;
