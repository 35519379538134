import React, {useEffect, useState} from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormInputText from "../../../forms/form-control/FormInputText";
import FormInputTextArea from "../../../forms/form-control/FormInputTextArea";
import FormSearchableSelect from "../../../forms/form-control/FormSearchableSelect";
import { FaPlus, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useAuthenticate } from "../../../../hooks/auth";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import SelectInTable from "../../../forms/form-control/daily/SelectInTable";
import { BREAKPOINTS } from '../../../../utils/constants';
import * as Shared from "../../../common/shared";

/**
 * CreateShopEntryTable Table Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateShopEntryTable = (props) => {
    const { register, control, errors, fields, append, remove, setValue, ecOptions, users, showButton,
        equipments, jobs, costCodes, payPeriod, watch, getValues, setShowButton, totalHours, setTotalHours
    } = props;
    const { user } = useAuthenticate();
    const ADMINISTRATOR = 1;
    const SHOP_SUPERVISOR = 14;
    const REGULAR_HOURS = 1;
    const TRAINING_REASON_ID = 27;
    const EQUIPMENT_SERVICES_COST_CODE_ID = 24;
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');

    useEffect(() => {
        const watchedReason = watch([
            "time_entries"
        ]);

        // set min and max date for date entry fields
        setMin(
            (payPeriod.start_date)
                ?
                    moment(new Date(payPeriod.start_date)).subtract(7, 'd').format('MM-DD-YY')
                :
                    ''
        );

        setMax(
            (payPeriod.start_date)
                ?
                    moment(new Date(payPeriod.end_date)).format('MM-DD-YY')
                :
                    ''
        )

        if (typeof watchedReason.time_entries !== 'undefined') {
            watchedReason.time_entries?.forEach((item, i) => {
                // show or hide other objects that doesn't need to be present on the form
                if (Number(item.ec_id) === REGULAR_HOURS) {
                    document.getElementById(`equipment_select_${i}`).classList.remove("d-none");
                    document.getElementById(`job_select_${i}`).classList.remove("d-none");
                    document.getElementById(`cost_code_select_${i}`).classList.remove("d-none");
                } else if (Number(item.ec_id) === TRAINING_REASON_ID) {
                    document.getElementById(`equipment_select_${i}`).classList.remove("d-none");
                    document.getElementById(`cost_code_select_${i}`).classList.remove("d-none");
                    document.getElementById(`job_select_${i}`).classList.add("d-none");
                } else {
                    document.getElementById(`equipment_select_${i}`).classList.add("d-none");
                    document.getElementById(`job_select_${i}`).classList.add("d-none");
                    document.getElementById(`cost_code_select_${i}`).classList.add("d-none");
                }
            });
        }
    }, [watch, payPeriod, setValue]);

    const removeRow = (index) => {
        remove(index);
        setTimeout(() => {
            calculateTotalHours();
        }, 300);
    }

    const calculateTotalHours = () => {
        let values = getValues();

        let total = 0;
        values.time_entries?.forEach((column) => {
            if (typeof column.hours !== 'undefined') {
                total += Number(column.hours);
            }
        });

        setTotalHours(Number(total).toFixed(2));
    }

    const MSHA_TRAINING_EQUIPMENT_NUMBER = '3';
    const SAFETY_TRAINING_COST_CODE = 94;

    const getAvailableEquipments = (reasonId) => {
        if (Number(reasonId) === REGULAR_HOURS) {
            return equipments.filter(equipment => equipment.equipment_number !== MSHA_TRAINING_EQUIPMENT_NUMBER);
        } else if (Number(reasonId) === TRAINING_REASON_ID) {
            const MSHA_TRAINING_EQUIPMENT = equipments.find(equipment => equipment.equipment_number === MSHA_TRAINING_EQUIPMENT_NUMBER);
            return MSHA_TRAINING_EQUIPMENT ? [MSHA_TRAINING_EQUIPMENT] : [];
        } else {
            return equipments;
        }
    }

    const getAvailableCodeCodes = (reasonId) => {
        if (Number(reasonId) === REGULAR_HOURS) {
            return costCodes.filter(costCode => costCode.code !== SAFETY_TRAINING_COST_CODE);
        } else if (Number(reasonId) === TRAINING_REASON_ID) {
            const SAFETY_TRAINING = costCodes.find(codeCode => codeCode.code === SAFETY_TRAINING_COST_CODE);
            return SAFETY_TRAINING ? [SAFETY_TRAINING] : [];
        } else {
            return equipments;
        }
    }

    const onReasonChange = (e, index) => {
        setValue(`time_entries[${index}].reason`, e.target.value);
        setValue(`time_entries[${index}].equipment_id`, null);
        setValue(`time_entries[${index}].cost_code_id`, null);
    }

    if (window.innerWidth <= BREAKPOINTS['lg']) {
        return (
            <div className="s-flex s-flex-col s-flex-gy">
                <div className="s-flex s-justify-between s-items-center s-flex-gx mb-3">
                    <FaPlus
                        color='#22af47'
                        size={20}
                        title="Add"
                        onClick={
                            () => {
                                append({});
                                setShowButton(true);
                            }
                        }
                    />
                    {showButton && (
                        <p className="form-control background_total_hours_block mb-0">
                            Total Hours: {totalHours}
                        </p>
                    )}
                    
                </div>
                {fields.map((f, index) => (
                    <div className="row s-gy" key={f.id}>
                        {([ADMINISTRATOR, SHOP_SUPERVISOR].includes(user?.group_id))
                            ?
                                <FormSearchableSelect
                                    label="Employee"
                                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                    id={`time_entries[${index}].user_id`}
                                    options={users}
                                    rules={{ required: "required" }}
                                    control={control}
                                    errors={errors}
                                />
                            :
                                <SelectInTable
                                    label="Employee"
                                    id={`time_entries[${index}].user_id`}
                                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                    name={`time_entries[${index}].user_id`}
                                    value={user?.id}
                                    options={[{ value: user?.id, label: user?.first_name + ' ' + user?.last_name }]}
                                    register={register({ required: "required" })}
                                    errors={errors}
                                />
                        }
                        <FormInputDate
                            label="Date"
                            id={`time_entries[${index}].date`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register({ required: "required" })}
                            min={min}
                            max={max}
                            errors={errors}
                        />
                        <FormSelect
                            label="Reason"
                            id={`time_entries[${index}].ec_id`}
                            options={ecOptions}
                            register={register({ required: "required" })}
                            errors={errors}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            onChange={(e) => onReasonChange(e, index)}
                        />
                        <FormInputNumber
                            label="Hours"
                            id={`time_entries[${index}].hours`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register({
                                required: {value: true, message: 'required'},
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 24.01, message: "max value is 24.00"}
                            })}
                            onInput={
                                (e) => {
                                    Shared.limitInputDaily(e.target, 5);
                                    calculateTotalHours();
                                }
                            }
                            errors={errors}
                        />
                        <FormInputNumber
                            label="Per Diem"
                            id={`time_entries[${index}].per_diem`}
                            type="number"
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            errors={errors}
                            register={register}
                        />
                        <FormInputText
                            id={`time_entries[${index}].reason`}
                            type={'hidden'}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register}
                            errors={errors}
                        />
                        <div
                            id={`equipment_select_${index}`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2 d-none"
                        >
                            {getValues(`time_entries[${index}].cost_code_id`)
                                ?
                                <FormSearchableSelect
                                    label="Equipment"
                                    id={`time_entries[${index}].equipment_id`}
                                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                    options={getAvailableEquipments(getValues(`time_entries[${index}].ec_id`))}
                                    rules={{ required: "required" }}
                                    control={control}
                                    errors={errors}
                                    customStyles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        })
                                    }}
                                    onChange={() => {
                                        setValue(`time_entries[${index}].job_id`, '');
                                    }}
                                />
                                :
                                <FormSearchableSelect
                                    label="Equipment"
                                    id={`time_entries[${index}].equipment_id`}
                                    // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                    options={getAvailableEquipments(getValues(`time_entries[${index}].ec_id`))}
                                    rules={{ required: false }}
                                    control={control}
                                    errors={errors}
                                    customStyles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        })
                                    }}
                                    onChange={() => {
                                        setValue(`time_entries[${index}].job_id`, '');
                                    }}
                                />
                            }
                        </div>
                        <div
                            id={`cost_code_select_${index}`}
                            className="col-xs-12 col-sm-6 col-md-3 d-none"
                        >
                            {getValues(`time_entries[${index}].equipment_id`)
                                ?
                                    <FormSearchableSelect
                                        label="Cost Code"
                                        id={`time_entries[${index}].cost_code_id`}
                                        // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                        options={getAvailableCodeCodes(getValues(`time_entries[${index}].ec_id`))}
                                        rules={{ required: "required" }}
                                        control={control}
                                        onChange={() => {
                                            setValue(`time_entries[${index}].job_id`, '');
                                        }}
                                        errors={errors}
                                        customStyles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: 200,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: 200,
                                            })
                                        }}
                                    />
                                :
                                    <FormSearchableSelect
                                        label="Cost Code"
                                        id={`time_entries[${index}].cost_code_id`}
                                        // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                        options={getAvailableCodeCodes(getValues(`time_entries[${index}].ec_id`))}
                                        rules={{ required: false }}
                                        control={control}
                                        onChange={() => {
                                            setValue(`time_entries[${index}].job_id`, '');
                                        }}
                                        errors={errors}
                                        customStyles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: 200,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: 200,
                                            })
                                        }}
                                    />
                            }
                        </div>
                        <div
                            id={`job_select_${index}`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2 d-none"
                        >
                            <FormSelect
                                label="Job"
                                id={`time_entries[${index}].job_id`}
                                // className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                options={jobs}
                                register={register}
                                onChange={() => {
                                    setValue(`time_entries[${index}].cost_code_id`, '');
                                    setValue(`time_entries[${index}].equipment_id`, '');
                                }}
                                errors={errors}
                            />
                        </div>
                        {
                            Number(getValues(`time_entries[${index}].ec_id`)) === REGULAR_HOURS &&
                            getValues(`time_entries[${index}].cost_code_id`)?.value === EQUIPMENT_SERVICES_COST_CODE_ID && (
                                <>
                                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                                        <FormInputNumber
                                            label="Eq. Hours"
                                            id={`time_entries[${index}].eq_hours`}
                                            className="col-md-q mb-3"
                                            register={register({required: false})}
                                            onInput={(e) => {
                                                Shared.limitInputDaily(e.target, 5);
                                                calculateTotalHours();
                                                }
                                            }
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                                        <FormInputNumber
                                            label="Comp Hours"
                                            id={`time_entries[${index}].comp_hours`}
                                            className="col-md-q mb-3"
                                            register={register({required: false})}
                                            onInput={(e) => {
                                                Shared.limitInputDaily(e.target, 5);
                                                calculateTotalHours();
                                                }
                                            }
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                                        <FormInputNumber
                                            label="Truck Hours"
                                            id={`time_entries[${index}].truck_hours`}
                                            className="col-md-q mb-3"
                                            register={register({required: false})}
                                            onInput={(e) => {
                                                Shared.limitInputDaily(e.target, 5);
                                                calculateTotalHours();
                                                }
                                            }
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                                        <FormInputNumber
                                            label="Miles"
                                            id={`time_entries[${index}].miles`}
                                            className="col-md-q mb-3"
                                            register={register({required: false})}
                                            onInput={(e) => {
                                                Shared.limitInputDaily(e.target, 5);
                                                calculateTotalHours();
                                                }
                                            }
                                            errors={errors}
                                        />
                                    </div>
                                </>
                            )
                        }
                        <FormInputTextArea
                            label="Notes"
                            id={`time_entries[${index}].notes`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register({ required: "required" })}
                            rows={1}
                            errors={errors}
                        />
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2 s-flex s-justify-between">
                            <FaPlus
                                color='#22af47'
                                size={20}
                                title="Add"
                                onClick={
                                    () => {
                                        append({});
                                        setShowButton(true);
                                    }
                                }
                            />
                            <FaTrash
                                color='#A51E37'
                                className=""
                                size={20}
                                title="Remove"
                                onClick={() => removeRow(index)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            <div className="form-row">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>
                                <div className="col-md-12 mb-3">
                                    <div className="float-left">
                                        <FaPlus
                                            color='#22af47'
                                            size={20}
                                            title="Add"
                                            onClick={
                                                () => {
                                                    append({});
                                                    setShowButton(true);
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="float-right">
                                        {showButton &&
                                            <div className="col-md-12 mb-3">
                                                <p className="form-control background_total_hours_block">
                                                    Total Hours: {totalHours}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <tr>
                                    {([ADMINISTRATOR, SHOP_SUPERVISOR].includes(user?.group_id))
                                        ?
                                        <td>
                                            <FormSearchableSelect
                                                label="Employee"
                                                className="col-md-q mb-3"
                                                id={`time_entries[${index}].user_id`}
                                                options={users}
                                                rules={{required: "required"}}
                                                control={control}
                                                errors={errors}
                                            />
                                        </td>
                                        :
                                        <SelectInTable
                                            label="Employee"
                                            id={`time_entries[${index}].user_id`}
                                            className="col-md-q mb-3"
                                            name={`time_entries[${index}].user_id`}
                                            value={user?.id}
                                            options={[{value: user?.id, label: user?.first_name + ' ' + user?.last_name}]}
                                            register={register({required: "required"})}
                                            errors={errors}
                                        />
                                    }
                                    <td>
                                        <FormInputDate
                                            label="Date"
                                            id={`time_entries[${index}].date`}
                                            register={register({required: "required"})}
                                            min={
                                                moment(new Date(payPeriod.start_date))
                                                    .subtract(7, 'd')
                                                    .format('YYYY-MM-DD')
                                            }
                                            max={moment(new Date(payPeriod.end_date)).format('YYYY-MM-DD')}
                                            errors={errors}
                                        />
                                    </td>
                                    <td>
                                        <FormSelect
                                            label="Reason"
                                            id={`time_entries[${index}].ec_id`}
                                            options={ecOptions}
                                            register={register({required: "required"})}
                                            errors={errors}
                                            className="col-md-q"
                                            onChange={(e) => onReasonChange(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <FormInputNumber
                                            label="Hours"
                                            id={`time_entries[${index}].hours`}
                                            className="col-md-q mb-3"
                                            register={register({
                                                required: {value: true, message: 'required'},
                                                min: {value: 0, message: "min value is 0"},
                                                max: {value: 24.01, message: "max value is 24.00"}
                                            })}
                                            onInput={(e) => {
                                                    Shared.limitInputDaily(e.target, 5);
                                                    calculateTotalHours();
                                                }
                                            }
                                            errors={errors}
                                        />
                                    </td>
                                    <td>
                                        <FormInputNumber
                                            label="Per Diem"
                                            id={`time_entries[${index}].per_diem`}
                                            type="number"
                                            className="col-md-q"
                                            errors={errors}
                                            register={register}
                                        />
                                        <FormInputText
                                            id={`time_entries[${index}].reason`}
                                            type={'hidden'}
                                            register={register}
                                            errors={errors}
                                        />
                                    </td>
                                    <td id={`equipment_select_${index}`} className="d-none">
                                        {getValues(`time_entries[${index}].cost_code_id`)
                                            ?
                                            <FormSearchableSelect
                                                label="Equipment"
                                                id={`time_entries[${index}].equipment_id`}
                                                options={getAvailableEquipments(getValues(`time_entries[${index}].ec_id`))}
                                                rules={{required: "required"}}
                                                control={control}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    })
                                                }}
                                                onChange={() => {
                                                    setValue(`time_entries[${index}].job_id`, '');
                                                }}
                                            />
                                            :
                                            <FormSearchableSelect
                                                label="Equipment"
                                                id={`time_entries[${index}].equipment_id`}
                                                options={getAvailableEquipments(getValues(`time_entries[${index}].ec_id`))}
                                                rules={{required: false}}
                                                control={control}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    })
                                                }}
                                                onChange={() => {
                                                    setValue(`time_entries[${index}].job_id`, '');
                                                }}
                                            />
                                        }
                                    </td>
                                    <td id={`cost_code_select_${index}`} className="d-none">
                                        {getValues(`time_entries[${index}].equipment_id`)
                                            ?
                                            <FormSearchableSelect
                                                label="Cost Code"
                                                id={`time_entries[${index}].cost_code_id`}
                                                options={getAvailableCodeCodes(getValues(`time_entries[${index}].ec_id`))}
                                                rules={{required: "required"}}
                                                control={control}
                                                onChange={() => {
                                                    setValue(`time_entries[${index}].job_id`, '');
                                                }}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    })
                                                }}
                                            />
                                            :
                                            <FormSearchableSelect
                                                label="Cost Code"
                                                id={`time_entries[${index}].cost_code_id`}
                                                options={getAvailableCodeCodes(getValues(`time_entries[${index}].ec_id`))}
                                                rules={{required: false}}
                                                control={control}
                                                onChange={() => {
                                                    setValue(`time_entries[${index}].job_id`, '');
                                                }}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 200,
                                                    })
                                                }}
                                            />
                                        }
                                    </td>
                                    <td id={`job_select_${index}`} className="d-none">
                                        <FormSelect
                                            label="Job"
                                            id={`time_entries[${index}].job_id`}
                                            options={jobs}
                                            register={register}
                                            className="col-md-q"
                                            onChange={() => {
                                                setValue(`time_entries[${index}].cost_code_id`, '');
                                                setValue(`time_entries[${index}].equipment_id`, '');
                                            }}
                                            errors={errors}
                                        />
                                    </td>
                                    <td>
                                        <FormInputTextArea
                                            label="Notes"
                                            id={`time_entries[${index}].notes`}
                                            register={register({ required: "required" })}
                                            rows={1}
                                            errors={errors}
                                        />
                                    </td>
                                    <td>
                                        <FaTrash
                                            color='#A51E37'
                                            className="marginTop36"
                                            size={20}
                                            title="Remove"
                                            onClick={() => removeRow(index)}
                                        />
                                    </td>
                                </tr>
                                {
                                    Number(getValues(`time_entries[${index}].ec_id`)) === REGULAR_HOURS &&
                                    getValues(`time_entries[${index}].cost_code_id`)?.value === EQUIPMENT_SERVICES_COST_CODE_ID && (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <FormInputNumber
                                                    label="Eq. Hours"
                                                    id={`time_entries[${index}].eq_hours`}
                                                    className="col-md-q mb-3"
                                                    register={register({required: false})}
                                                    onInput={(e) => {
                                                            Shared.limitInputDaily(e.target, 5);
                                                            calculateTotalHours();
                                                        }
                                                    }
                                                    errors={errors}
                                                />
                                            </td>
                                            <td>
                                                <FormInputNumber
                                                    label="Comp Hours"
                                                    id={`time_entries[${index}].comp_hours`}
                                                    className="col-md-q mb-3"
                                                    register={register({required: false})}
                                                    onInput={(e) => {
                                                            Shared.limitInputDaily(e.target, 5);
                                                            calculateTotalHours();
                                                        }
                                                    }
                                                    errors={errors}
                                                />
                                            </td>
                                            <td style={{minWidth: '80px'}}>
                                                <FormInputNumber
                                                    label="Truck Hours"
                                                    id={`time_entries[${index}].truck_hours`}
                                                    className="col-md-q mb-3"
                                                    register={register({required: false})}
                                                    onInput={(e) => {
                                                            Shared.limitInputDaily(e.target, 5);
                                                            calculateTotalHours();
                                                        }
                                                    }
                                                    errors={errors}
                                                />
                                            </td>
                                            <td style={{minWidth: '80px'}}>
                                                <FormInputNumber
                                                    label="Miles"
                                                    id={`time_entries[${index}].miles`}
                                                    className="col-md-q mb-3"
                                                    register={register({required: false})}
                                                    onInput={(e) => {
                                                            Shared.limitInputDaily(e.target, 5);
                                                            calculateTotalHours();
                                                        }
                                                    }
                                                    errors={errors}
                                                />
                                            </td>
                                        </tr>    
                                    )
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CreateShopEntryTable;
