import React, {useEffect, useRef, useState} from 'react';
import TableScrollable from "./tableScrollable";

/**
 * Component to build a react-table with export options
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TableReport = (prop) => {
    let {
        columns,
        data,
        getExportFileName,
        exportOptions,
        withGlobalSearch,
        fixedTotalRow = false,
        isSortDisabled = false,
        lastRowsToExcludeFromSort = 0,
        totalRowCallback,
    } = prop;

    const componentRef = useRef()
    const { width } = useContainerDimensions(componentRef)

    return (
        <div ref={componentRef} style={{ width: "100%" }} >
            {(width > 0) && <TableScrollable {...{columns, data, width, getExportFileName, exportOptions, withGlobalSearch, fixedTotalRow, isSortDisabled, lastRowsToExcludeFromSort, totalRowCallback}} />}
        </div>
    );
}

const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const getDimensions = () => ({
            width: myRef.current.offsetWidth,
            height: myRef.current.offsetHeight
        })

        if (myRef.current) {
            setDimensions(getDimensions())
        }
    }, [myRef])

    return dimensions;
};

export default TableReport;
