import {AccordionItem} from "react-light-accordion";
import React from "react";
import TextAreaInForm from "../../../../forms/form-control/daily/TextAreaInForm";

const DailyNotes = (props) => {
    const { register, daily, action } = props;
    return (
            <AccordionItem className="card-header bg-primary" title="Notes">
                <div className="collapse show">
                    <div className="form-row margin">
                        <TextAreaInForm
                            label="Client Notes"
                            className="col-md-4 mb-3"
                            id={`notes.notes`}
                            name={`notes.client_notes`}
                            rows="3"
                            cols="33"
                            value={(action === 'edit') ? daily.client_notes : ''}
                            register={register({required: false})}
                        />
                        <TextAreaInForm
                            label="Daily Notes"
                            className="col-md-4 mb-3"
                            id={`notes.notes`}
                            name={`notes.daily_notes`}
                            rows="3"
                            cols="33"
                            value={(action === 'edit') ? daily.daily_notes : ''}
                            register={register({required: false})}
                        />
                        <TextAreaInForm
                            label="Internal Notes"
                            className="col-md-4 mb-3"
                            id={`notes.notes`}
                            name={`notes.internal_notes`}
                            rows="3"
                            cols="33"
                            value={(action === 'edit') ? daily.internal_notes : ''}
                            register={register({required: false})}
                        />
                    </div>
                </div>
            </AccordionItem>
    );
}

export default React.memo(DailyNotes);