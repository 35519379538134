import styled from "styled-components";

export const TableStyle = styled.div`
  .table {
    .body {
      .tr.total {
        .td {
          border-top: 5px double black;
          font-weight: bold;
        }
      }
    }
  }
`;
