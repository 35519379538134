import React, {useMemo, useRef, useState} from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import { hideTooltipForDatasetsPlugin } from '../../../../../../utils/hideTooltipForDatasets';
import {ShiftReportTooltip} from './shiftReportTooltip';
import { createPortal } from 'react-dom';
import {formatNumberToDisplayWithCommas} from '../../../../../common/shared';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, hideTooltipForDatasetsPlugin);

const HeadcountPerShiftChart = ({chartData, withSummaryTooltip = false}) => {
    const chartRef = useRef(null);

    const [tooltipData, setTooltipData] = useState(null);

    const dataset = useMemo(() => chartData.items, [chartData.items]);
    const labels = useMemo(() => chartData.items.map(item => item.date), [chartData.items]);
    const avgRollingDataset = useMemo(() => chartData.items.map(item => item.avg_rolling_30_days), [chartData.items]);
    const targetDataset = useMemo(() => chartData.items.map(item => item.target), [chartData.items]);

    const data = useMemo(() => ({
        labels: labels,
        datasets: [
            {
                type: 'bar',
                data: dataset,
                backgroundColor: '#bfbfbf',
                order: 2,
                label: 'Headcount Overage',
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'overage'
                },
            },
            {
                type: 'line',
                data: avgRollingDataset,
                backgroundColor: '#000000',
                borderColor: '#000000',
                order: 0,
                label: 'Rolling 30-Day Avg',
            },
            {
                type: 'line',
                data: targetDataset,
                backgroundColor: '#c00000',
                borderColor: '#c00000',
                order: 1,
                label: 'Target',
            }
        ],
    }), [avgRollingDataset, dataset, labels, targetDataset]);

    const options = useMemo(() => ({
        scales: {
            y: {
                title: 'Downtime',
                beginAtZero: true,
                suggestedMin: -0.5,
                suggestedMax: 0.5,
                ticks: {
                    autoSkip: false,
                    stepSize: 0.5,
                }
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickLength: 10,
                },
                ticks: {
                    autoSkip: false,
                    callback: (_, i) => i % 7 ? '' : labels[i]
                }
            },
        },
        plugins: {
            legend: {
                reverse: true,
            },
            tooltip: {
                animation: false,
                hideForDatasetIndexes: withSummaryTooltip ? [0] : undefined,
                external: ({tooltip, chart}) => {
                    if (!withSummaryTooltip) {
                        return;
                    }
                    if (tooltip.dataPoints.length === 1 && tooltip.dataPoints[0].datasetIndex === 0) {
                        if (tooltip.opacity === 0) {
                            setTooltipData(null);
                            return;
                        }
                        
                        const {left: positionX, top: positionY} = chart.canvas.getBoundingClientRect();
                        const dataIndex = tooltip.dataPoints[0].dataIndex;
                        const data = tooltip.dataPoints[0].dataset.data[dataIndex];
                        const detailData = data.shifts_details;

                        const offsetY = data.overage >= 0 ? tooltip.caretY : tooltip.caretY - tooltip.dataPoints[0].element.height;
                        setTooltipData({
                            x: positionX + tooltip.caretX,
                            y: positionY + offsetY + document.documentElement.scrollTop,
                            detailData,
                        })
                    } else {
                        setTooltipData(null);
                    }
                }
            },
        },
        maxBarThickness: 20,
    }), [labels, withSummaryTooltip])

    return (
        <div className="p-0 m-0">
            <div className="row">
                <div className="col-3 d-flex flex-column align-items-center justify-content-center p-4">
                    <h3 className="font-weight-bold h6 mb-4 text-center">Headcount Per Shift</h3>
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex">
                            <div className="d-flex flex-column align-items-center justify-content-center mr-3">
                                <p className="m-0 p-0 font-italic font-weight-bold">Driller</p>
                                <p className="mb-0 p-0 font-weight-bold h6">{formatNumberToDisplayWithCommas(chartData.driller_monthly_avg, 1)}</p>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <p className="m-0 p-0 font-italic font-weight-bold text-secondary">Assistant</p>
                                <p className="mb-0 p-0 font-weight-bold h6">{formatNumberToDisplayWithCommas(chartData.assistant_monthly_avg, 1)}</p>
                            </div>
                        </div>
                        <p className="mb-4 p-0 font-italic">Monthly Avg</p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex">
                            <div className="d-flex flex-column align-items-center justify-content-center mr-3">
                                <p className="m-0 p-0 font-italic font-weight-bold">Driller</p>
                                <p className="mb-0 p-0 font-weight-bold h6">{formatNumberToDisplayWithCommas(chartData.driller_rolling_30_days_avg, 1)}</p>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <p className="m-0 p-0 font-italic font-weight-bold text-secondary">Assistant</p>
                                <p className="mb-0 p-0 font-weight-bold h6">{formatNumberToDisplayWithCommas(chartData.assistant_rolling_30_days_avg, 1)}</p>
                            </div>
                        </div>
                        <p className="mb-4 p-0 font-italic">Rolling 30-Day Avg</p>
                    </div>
                </div>
                <div className="col-9 pl-0">
                    <Bar ref={chartRef} data={data} options={options}/>
                    {tooltipData && createPortal(<ShiftReportTooltip data={tooltipData} />, document.body)}
                </div>
            </div>
        </div>
    )
}

export default HeadcountPerShiftChart;
